<template>
  <div>
    <v-row>

      <v-col cols="4">
    {{day}} :
  </v-col>
      <v-col cols="6">
        <template v-if="day_tab.length > 0">
<v-chip class="mx-1" v-for="(v, i) in day_tab" :key="day+i">{{v.ouverture}}-{{v.fermeture}}</v-chip><br>
</template>
<template v-if="!day_tab.length">
Fermé<br>
</template>
</v-col>
</v-row>
</div>
</template>

<script>

  export default {
    components:{


    },
    props:['value', 'day'],
    data () {
      return {

          day_tab:[]
      }
    },
    created:function() {
      if(this.value) {
        this.day_tab = this.value
      }


    },

  }
</script>
