<template>

  <div>
    <v-row>
      <v-col cols="12">
  <v-file-input multiple accept="image/jpeg" type="file"  v-model="files" @change="detectFiles(files)" show-size label="Ajouter une photo de votre commerce"> ></v-file-input>
</v-col>
<v-col cols="12" class="text-center">
  <v-progress-circular
  v-if="is_uploading"
   :rotate="90"
   :size="100"
   :width="15"
   :value="bufferload"
   color="red"
 >
   {{ bufferload }}
 </v-progress-circular>
</v-col>
</v-row>
    <v-row>
      <draggable class="row wrap fill-height align-center sortable-list p3"  v-model="tab_img"  @end="dragend">
          <v-col  v-for="(n, i) in tab_img"   :key="'img'+ i"   class="d-flex child-flex"  cols="4">
            <v-card  class="mx-auto">
              <v-img  :src="n.url_norm"  aspect-ratio="1" class="grey lighten-2" ></v-img>
              <v-card-actions>
                   <v-btn icon @click="tab_img.splice(i, 1)">
                     <v-icon>mdi-delete</v-icon>
                   </v-btn>
                 </v-card-actions>
               </v-card>
          </v-col>
        </draggable>
  </v-row>

  </div>

</template>

<script>
import firebase from 'firebase'
import draggable from 'vuedraggable'
export default {
  components:{
    draggable
  },
  props: ['value'],
  data () {
    return {
      progressUpload: 0,
      file: File,
      files:undefined,
      uploadTask: '',
      userId:null,
      is_uploading:false,
      bufferload:0,
      tab_img:[],
    }
  },
  watch: {
    value: function (val) {
    this.tab_img = val;
    },
    uploadTask: function() {
      this.uploadTask.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.$emit('url', downloadURL)
        })
      })
    }
  },
  created:function() {
    if(this.value) {
      this.tab_img = this.value
    }
      this.userId = firebase.auth().currentUser.uid;

  },
  methods: {
    dragend() {

          this.$emit('input', this.tab_img)
    },
    detectFiles (fileList) {
      Array.from(Array(fileList.length).keys()).map( x => {
        this.upload(fileList[x])
      })
    },
    isFileImage(file) {
    const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file['type'])
},
    upload (file) {


    if (!this.isFileImage(file)) {
console.log("Please select a file!");
  return;
}
let that = this
this.is_uploading = true;
const storageRef=firebase.storage().ref("pictures/" + this.userId + "/" + this.CreateObjectId() + '.' + file.name.split('.').pop()).put(file);
storageRef.on(`state_changed`,snapshot=>{ this.bufferload = Math.round((snapshot.bytesTransferred/snapshot.totalBytes)*100);
  }, error=>{console.log(error.message)},
()=>{this.bufferload=100;
  this.is_uploading = false;



    storageRef.snapshot.ref.getDownloadURL().then((url)=>{
      var t = storageRef.snapshot.ref.location.path.replace('.'+storageRef.snapshot.ref.location.path.split('.').pop(), '').replace("pictures/" + this.userId + "/", "pictures/" + this.userId + "/miniatures/")
      t = t + "_600x600.jpg";
      console.log(t)
      var t_mini = storageRef.snapshot.ref.location.path.replace('.'+storageRef.snapshot.ref.location.path.split('.').pop(), '').replace("pictures/" + this.userId + "/", "pictures/" + this.userId + "/miniatures/")
      t_mini = t_mini + "_200x200.jpg";

      var myinter = setInterval(geturl, 1000);

function geturl() {

  firebase.storage().ref(t).getDownloadURL().then((url_norm)=>{
          if(url_norm) {
            firebase.storage().ref(t_mini).getDownloadURL().then((url_mini)=>{
                    if(url_mini) {

                          that.tab_img.push({url_img:url, url_norm: url_norm, url_mini:url_mini, path_norm:t, path_mini:t_mini, path:storageRef.snapshot.ref.location.path})
                          that.$emit('input', that.tab_img)
                            clearInterval(myinter);
                            console.log(that.tab_img)
                    }
              })


          }
    })

}




      });
    }
  );



    },
    CreateObjectId() {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16);

    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
}

  },

}
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
</style>
