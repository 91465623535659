<template>
<v-container>


  <v-tabs>
    <v-tab>Mes coordonnées</v-tab>
    <v-tab>Mes favoris</v-tab>
    <v-tab-item>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >


          <v-form>
            <v-container class="py-0">
              <v-row>
                  <v-col cols="12" md="4" >
                    <v-text-field label="Adresse Email" class="purple-input" :value="currentUser.email" disabled/>
                  </v-col>

                  <v-col  cols="12" class="text-right">

                  </v-col>
              </v-row>
            </v-container>
          </v-form>

      </v-col>
    </v-row>
  </v-tab-item>
  <v-tab-item>
    
  </v-tab-item>
        </v-tabs>
  </v-container>
</template>

<script>
import firebase from "firebase";

  export default {
    components:{

    },
    data () {
      return {

        is_loading: false,
        userId:null,
        data_user: {},
        successful: false,
        message: '',
        error:null,
        secondaryApp:null,
        dialog_createuser:false,
        type_user:'operateur',
        email_user:null,
        user_user:null,
        password_user:null,
        valid_form:false,
        is_compte_commerce:false,
        desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
          },
          {
            name: 'Eclair',
            calories: 262,
          },
          {
            name: 'Cupcake',
            calories: 305,
          },
          {
            name: 'Gingerbread',
            calories: 356,
          },
          {
            name: 'Jelly bean',
            calories: 375,
          },
          {
            name: 'Lollipop',
            calories: 392,
          },
          {
            name: 'Honeycomb',
            calories: 408,
          },
          {
            name: 'Donut',
            calories: 452,
          },
          {
            name: 'KitKat',
            calories: 518,
          },
        ],

      }
    },

    mounted () {





           this.userId = firebase.auth().currentUser.uid
           this.is_loading =true;
              this.$db.collection("users").doc(this.userId).get().then((querySnapshot) => {
             console.log(querySnapshot.data())
             this.data_user = querySnapshot.data() || {};
             this.is_compte_commerce = this.data_user.is_compte_commerce;
              this.is_loading =false;
           });

    },

    computed: {
   currentUser() {
     return firebase.auth().currentUser
   },
 },
 methods: {
   updateProfile() {


        let to_update = {
       company:this.data_user.company || "",
       username:this.data_user.username || "",
       firstname:this.data_user.firstname || "",
       lastname:this.data_user.lastname || "",
       address:this.data_user.address || "",
       city:this.data_user.city || "",
       country:this.data_user.country || "",
       postalcode:this.data_user.postalcode || "",

     }
     this.is_loading =true;
     this.$db.collection("users").doc(this.userId).set(to_update, { merge: true }).then(() => {
  console.log("Document successfully written!");
     this.is_loading =false;
  });


   },

 }
  }
</script>
