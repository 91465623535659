<template>
<v-row>
  <v-col cols="2">{{day}}</v-col>
  <v-col>
  <template v-for="(t, i) in array_hour">
    <v-row :key="'ar'+i" >
  <v-col><addtime  @change="$emit('input', array_hour)" v-model="t.ouverture" label="Heure d'ouverture"> </addtime>  </v-col>

    <v-col>
    <addtime @change="$emit('input', array_hour)" v-model="t.fermeture" label="Heure de fermeture"></addtime>  </v-col>
  <v-col><v-btn x-small @click="array_hour.splice(i, 1), $emit('input', array_hour)"><v-icon>mdi-delete</v-icon></v-btn></v-col>

</v-row>

</template>

<v-col cols="1"><v-btn x-small @click="array_hour.push({})">Ajouter un horaire</v-btn></v-col>
</v-col>


</v-row>

</template>

<script>
import addtime from '@/components/addtime'
  export default {
    components:{
        addtime

    },
    props:['value', 'day'],
    data () {
      return {
        time: null,
        modal2: false,
          array_hour:[]
      }
    },
    created:function() {
      if(this.value) {
        this.array_hour = this.value
      }


    },

  }
</script>
