<template>
  <div>
  <v-dialog
  ref="dialog"
  v-model="modal2"
  :return-value.sync="time"
  persistent
  width="290px"
  >
  <template v-slot:activator="{ on, attrs }">
  <v-text-field
  v-model="time"
  :label="label"
  readonly
  v-bind="attrs"
  v-on="on"
  ></v-text-field>
  </template>
  <v-time-picker
  v-if="modal2"
  v-model="time"
  format="24hr"
  full-width
  >
  <v-spacer></v-spacer>
  <v-btn
  text
  color="primary"
  @click="modal2 = false"
  >
  Cancel
  </v-btn>
  <v-btn
  text
  color="primary"
  @click="$refs.dialog.save(time), $emit('input', time), $emit('change', time)"
  >
  OK
  </v-btn>
  </v-time-picker>
  </v-dialog>

</div>
</template>

<script>
  export default {
    props:['value', 'label'],
    data () {
      return {
        time: null,
        modal2: false,

      }
    },
    created:function() {
      if(this.value) {
        this.time = this.value
      }


    },

  }
</script>
