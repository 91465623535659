<template>
<div>

  <v-card   class="grey lighten-3"  :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
      <v-container>
  <account_user v-if="!is_compte_commerce"></account_user>
  <account_commerce v-if="is_compte_commerce"></account_commerce>
</v-container>
</v-card>
</div>
</template>

<script>
import firebase from "firebase";
import account_user from "@/components/account/account_user";
import account_commerce from "@/components/account/account_commerce";
  export default {
    components: {
      account_user,
      account_commerce
    },
    data () {
      return {

        is_loading: false,
        userId:null,
        data_user: {},
        successful: false,
        message: '',
        error:null,
        secondaryApp:null,
        dialog_createuser:false,
        type_user:'operateur',
        email_user:null,
        user_user:null,
        password_user:null,
        valid_form:false,
        is_compte_commerce:false,


      }
    },

    mounted () {





           this.userId = firebase.auth().currentUser.uid
           this.is_loading =true;
              this.$db.collection("users").doc(this.userId).get().then((querySnapshot) => {
             console.log(querySnapshot.data())
             this.data_user = querySnapshot.data() || {};
             this.is_compte_commerce = this.data_user.is_compte_commerce;
              this.is_loading =false;
           });

    },

    computed: {
   currentUser() {
     return firebase.auth().currentUser
   },
 },
 methods: {
   updateProfile() {


        let to_update = {
       company:this.data_user.company || "",
       username:this.data_user.username || "",
       firstname:this.data_user.firstname || "",
       lastname:this.data_user.lastname || "",
       address:this.data_user.address || "",
       city:this.data_user.city || "",
       country:this.data_user.country || "",
       postalcode:this.data_user.postalcode || "",

     }
     this.is_loading =true;
     this.$db.collection("users").doc(this.userId).set(to_update, { merge: true }).then(() => {
  console.log("Document successfully written!");
     this.is_loading =false;
  });


   },

 }
  }
</script>
