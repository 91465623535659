<template>
<div class="map_carte">
<section>

<v-card  height="200px" >

<l-map  v-if="load" style="height: 100%; z-index: 1;" :zoom="zoom" :center="center" >
<l-tile-layer url="https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=c80688e51ba844f6b00ecd4720ba318a"></l-tile-layer>



	<l-marker v-if="show" :lat-lng="marker_pos">
    <l-icon  :icon-anchor="[20, 20]">
			<v-avatar  size="40" dense color="white">
				<v-icon>
	mdi-store
</v-icon>

</v-avatar>

            </l-icon>


	</l-marker>

</l-map>
</v-card>


</section>
</div>
</template>

<script>
import { LMap, LTileLayer,  LMarker,  LIcon, } from 'vue2-leaflet';

import {  icon } from "leaflet";
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;

export default {
  components: {  LMap,
    LTileLayer,
    LMarker, LIcon
	},
  props:['data'],
  data () {
    return {
url: 'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/VIIRS_CityLights_2012/default/',
zoom: 10,
center: [46.662475, 5.5729159],
marker_pos: [0,0],
show:false,
geojson:[],
icon: icon({
        iconUrl: "img/logo_b.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
iconSize: 64,
tab_position:[],
is_loading:false,
  distance: { label: 'Distance', val: 50, color: 'black' },

            load:false,
   };
  },
  mounted() {

	document.title = 'Solve';
  if(this.data) {
    console.log(this.data)
    this.marker_pos =  this.data
    this.center = this.data
    this.show =true;
  }

this.load =true;

},
watch: {
  data: function(newVal) { // watch it
        this.marker_pos =  newVal
        this.center = newVal
        this.show =true;
        }
      },


  methods: {



  }
}
</script>
<style>

.leaflet-popup-tip-container {
  display:none;
}

.leaflet-popup-content-wrapper {
  border-radius: 0px;
  padding: 0;
   width:100%;

   background-color:rgb(255,255,255,0);
}
.leaflet-popup-content {
  padding: 0;
  margin:0;

}
.leaflet-popup  {
  padding: 0;
}

.custom-control {

  padding:6px;
}
.full_card {
  width:100%;
}
</style>
