<template>
  <div>
      <v-card class="grey lighten-3"  :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >
    <v-container  v-if="data_load">
    <v-row>
      <v-col cols="12"  md="1">
        <v-btn   fab x-large  top   left  color="primary"  to="/map"> <v-icon>mdi-arrow-left</v-icon></v-btn>
      </v-col>
      <v-col cols="12"  md="11">


      <v-row>

        <v-col cols="12"  md="4"  sm="7" xs="12">

          <v-card>
            <v-card-text>
            <p class="display-1 mb-0">{{current_commerce.name_commerce.toUpperCase()}}</p>
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4"  dark>mdi-email-outline </v-icon>
                </v-list-item-avatar>
                 <v-list-item-content>{{current_commerce.email}}</v-list-item-content>

              </v-list-item>

              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4"  dark>mdi-map-marker</v-icon>
                </v-list-item-avatar>
                 <v-list-item-content>
              {{current_commerce.address}}
                 </v-list-item-content>

              </v-list-item>
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4"  dark>mdi-phone</v-icon>
                </v-list-item-avatar>

              <v-list-item-content>{{current_commerce.numero_phone}}</v-list-item-content>

              </v-list-item>
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4"  dark>mdi-web</v-icon>
                </v-list-item-avatar>

                <v-list-item-content><a target="_blank" :href="current_commerce.website">{{current_commerce.website}}</a></v-list-item-content>

              </v-list-item>

                <template v-for="(p, i) in Object.keys(current_commerce.categories)" >
                  <v-chip  :key="'class'+ i" class="ma-2" label tile>

                      {{GetNameClass(p)}}
                      </v-chip>
                </template>

            </v-list>
             <vue-yandex-share class="ml-0" v-bind="options"></vue-yandex-share>
          </v-card-text>
        </v-card>

          <template v-if="current_commerce.tab_img.length != undefined" >
        <v-carousel height="300" class="mt-4">
          <v-carousel-item v-for="(img, i) in current_commerce.tab_img" :key="'img' + i" :src="img.url_norm"
          :lazy-src="img.url_mini">
          </v-carousel-item>

        </v-carousel>
      </template>
  </v-col>

  <v-col cols="12"  md="8"  sm="12" xs="12">
    <v-card min-height="500">
      <v-card-text>
    <v-tabs>
      <v-tab>A propos</v-tab>
      <v-tab>Produits</v-tab>

      <v-tab>Moyens de paiement</v-tab>
      <v-tab>Options de livraison</v-tab>

      <v-tab-item>

        <p class="mt-5">   {{current_commerce.description}} </p>
        <p  class="mt-10 subtitle-1"><b>Horaires </b></p>
          <p>
          <template v-if="current_commerce.horaires" >
          <showday v-model="current_commerce.horaires.lundi" :day="'Lundi'"></showday>
          <showday v-model="current_commerce.horaires.mardi" :day="'Mardi'"></showday>
          <showday v-model="current_commerce.horaires.mercredi" :day="'Mercredi'"></showday>
          <showday v-model="current_commerce.horaires.jeudi" :day="'Jeudi'"></showday>
          <showday v-model="current_commerce.horaires.vendredi" :day="'Vendredi'"></showday>
          <showday v-model="current_commerce.horaires.samedi" :day="'Samedi'"></showday>
          <showday v-model="current_commerce.horaires.dimanche" :day="'Dimanche'"></showday>
        </template>
        </p>




      </v-tab-item>
      <v-tab-item>
        <p class="pt-10 subtitle-1 font-weight-thin">
          <v-list>
            <v-list-item v-for="(file, i) in current_commerce.tab_files" :key="'file'+i">
              <v-list-item-avatar color="primary">
                <v-icon class="elevation-4"  dark>mdi-file </v-icon>
              </v-list-item-avatar>

               <v-list-item-content>{{file.name}}</v-list-item-content>
               <v-list-item-avatar color="primary">

                 <v-btn  icon target="_blank" :href="file.url_file">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>

               </v-list-item-avatar>

            </v-list-item>
          </v-list>

        </p>
      </v-tab-item>

      <v-tab-item>
        <p class="pt-10 subtitle-1 font-weight-thin">
          <v-list>
            <template v-for="(p, i) in items_payment" >

            <v-list-item v-if="current_commerce.payment[p.name]" :key="'file'+i">
              <v-list-item-avatar color="primary">
                <v-icon class="elevation-4"  dark>mdi-currency-eur </v-icon>
              </v-list-item-avatar>

               <v-list-item-content>{{p.text}}</v-list-item-content>


            </v-list-item>
          </template>
          </v-list>

        </p>
      </v-tab-item>
      <v-tab-item>
        <p class="pt-10 subtitle-1 font-weight-thin">
          <v-list>
            <template v-for="(p, i) in items_expe" >

            <v-list-item v-if="current_commerce.reception[p.name]" :key="'file'+i">
              <v-list-item-avatar color="primary">
                <v-icon class="elevation-4"  dark>mdi-truck-delivery </v-icon>
              </v-list-item-avatar>

               <v-list-item-content>{{p.text}}</v-list-item-content>


            </v-list-item>
          </template>
          </v-list>

        </p>
      </v-tab-item>



    </v-tabs>
  </v-card-text>
</v-card>


  </v-col>
      </v-row>



  </v-col>
</v-row>
    </v-container>
  </v-card>
  </div>
</template>
<script>

import firebase from 'firebase'
import showday from '@/components/showdayhoraire'
import VueYandexShare from '@alexlit/vue-yandex-share';
    export default {
      components : {
showday,
VueYandexShare
      },
        data: () => ({
            rating:4.5,
            product:{name:"Nom du produit", price:"0", description:"Description du produit", version_product:[], tab_img:[]},
            commerce_id: "",
            data_load:false,
            userId:null,
            current_commerce:[],
            items_name: [
 {name:'fruits_legumes', text:'Fruits et légumes' },
 {name:'viandes_poissons', text: 'Viandes et poissons ' },
 {name:'pains_patisseries', text: 'Pains et pâtisseries' },
 {name:'frais', text: 'Frais' },
 {name: 'epicerie_salee', text:'Épicerie salée ' },
 {name:'epicerie_sucree', text: 'Épicerie sucrée ' },
 {name:'bio', text: 'Bio et écolo ' },
 {name:'boissons', text: 'Boissons ' },
            {name:'vetements', text:  'Vêtements ' },
            {name:'chaussures', text:  'Chaussures ' },
            {name:'bijoux_montres', text:  'Bijoux et montres ' },
            {name:'maroquinerie_bagagerie', text:  'Maroquinerie et Bagagerie ' },
            {name:'sport', text:  'Sport ' },
            {name: 'maquillage', text:'Maquillage ' },
            {name: 'soins_visage', text:'Soins visage' },
            {name: 'soins_corps', text:'Soins corps ' },
            {name: 'dentaire', text:'Hygiène dentaire ' },
            {name: 'capillaire', text:'Capillaire ' },
            {name:'bebe', text: 'Bébé ' },
          {name:'livres', text: 'Livres ' },
          {name:'jeux', text: 'Jeux' },
          {name: 'high_tech', text:'High-Tech ' },
          {name:'sport', text:'Sport ' },
        {name:'decoration', text:  'Décoration ' },
        {name:'art_table', text:  'Art de la table' },
        {name:'meubles', text:  'Meubles' },
        {name:'entretien_nettoyage', text:  'Entretien et nettoyage ' },
        {name:'bricolage_jardin', text:  'Bricolage et Jardin ' },
 ],
 items_payment: [
   {name:'especes', text:  'Espèces' },
   {name:'carte', text:  'Carte bancaire' },
   {name:'cheques', text:  'Chèques' },
 ],
 items_expe: [
   {name:'commerce', text:  'Retrait en magasin' },
   {name:'colis', text:  'Livraison à domicile' },
 ],
messages:{},
message:"",
current_user_id: null,
login:null,
is_login:false,
options: {
        accessToken: null,
        bare: false,
        counter: false,
        copy: 'last',
        description: null,
        direction: 'horizontal',
        hashtags: null,
        image: null,
        lang: 'ru',
        limit: 24,
        popupDirection: 'bottom',
        popupPosition: 'inner',
        size: 'm',
        title: null,
        url: null,
        services: [
          'facebook',
          'gplus',
          'linkedin',
          'pinterest',
          'skype',
          'telegram',
          'twitter',
          'viber',
          'whatsapp',
        ],
      }
        }),
        created:function() {
          if(this.currentUser()) {

            this.is_login = true;
            this.login = this.currentUser().email;
              this.current_user_id = this.currentUser().uid

          }


console.log(this.$route.params.commerce)
          if(this.$route.params.commerce) {
            this.commerce_id = this.$route.params.commerce;
            this.getcommerce()
          }






        },
        methods: {

          currentUser() {
            return firebase.auth().currentUser
          },
          GetNameClass(val) {

              for (var i = 0; i < this.items_name.length; i++) {

              if(this.items_name[i].name == val) {

                return this.items_name[i].text;

              }
              }

          },
          getcommerce() {
            this.data_load =false;
          this.$db.collection("commerce").doc(this.commerce_id).get().then((querySnapshot) => {
        console.log(querySnapshot.data())
        this.current_commerce = querySnapshot.data()
        this.data_load = true;
      })
          },
          sendMessage() {

const message = {
        message: this.message,
        userid:this.current_user_id,
        login: this.login,
        date: Date.now(),
      };
      firebase
        .database()
        .ref("chat/" + this.commerce_id + "/messages/" + this.current_user_id)
        .push(message);
this.message = "";
        let updates = {}
updates['chat/' + this.commerce_id + '/users/' + this.current_user_id] = {lastupdate: Date.now(),   name: this.login, userid: this.current_user_id}
updates['userschat/' + this.current_user_id + '/commerces/' + this.commerce_id] = {lastupdate: Date.now(),   commerce_id: this.commerce_id, name: this.current_commerce.name_commerce}
firebase.database().ref().update(updates)


  },



        }
    }
</script>
