<template>
  <div>

      <v-virtual-scroll
  height="500"
  :items="messages"
   item-height="70"

       >
         <template v-slot:default="{ item, i }">

           <v-row
          no-gutters class="mt-2 pa-4" :key="'message' + i"
         >
           <template v-if="current_user_id != item.userid">
             <v-col  cols="6"  sm="6"  md="6"  :key="'message' + i" >
               <v-card  class="pa-2 white lighten-3" outlined   >
                  {{item.message }}
               </v-card>
             </v-col>
                  <v-col  cols="6"  sm="6"  md="6">

                  </v-col>
         </template>

           <template v-if="current_user_id == item.userid">
         <v-col  cols="6"  sm="6"  md="6">
         </v-col>
            <v-col class="mt-2" cols="6"  sm="6"  md="6" :key="'message' + i" >
              <v-card class="pa-2 div_my"   >
                 {{item.message }}
              </v-card>
            </v-col>



         </template>
         </v-row>

         </template>
       </v-virtual-scroll>


  </div>
</template>
<script>

    export default {
      components : {

      },
      props:['messages', 'current_user_id'],
        data: () => ({

        }),
        created:function() {





        },
        methods: {
        }
      }
</script>
<style>
.div_my {
  background-color:#B0C5B9;
}

</style>
