<template>
  <div>
<v-container>
<v-row>
  <v-col  cols="12" md="4">
    <v-card>
      <v-card-text>
    <v-list>
      <v-list-item
        v-for="(n,i) in list_communication"
        :key="'com' + i"
        link
      @click="getmessages(n.userid), select_comme=true, current_id_com = n.userid"
      >

    <v-list-item-avatar color="primary">
      <v-icon dark>
        mdi-account-circle
      </v-icon>
    </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ n.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
  </v-card>

  <v-img class="mt-4" src="/img/mess.jpg"></v-img>

  </v-col>
<v-col v-if="select_comme" cols="12" md="8" >
  <v-card>
    <v-card-text>
    <pagemessage :messages="messages" :current_user_id="current_user_id"></pagemessage>



</v-card-text>

<v-card-actions>
<v-text-field
    v-model="message"
    label="Message"
    clearable
  ></v-text-field>

<v-btn @click="sendMessage">Envoyer</v-btn>

</v-card-actions>
</v-card>

</v-col>
</v-row>
</v-container>
  </div>
</template>
<script>
import firebase from 'firebase'
import pagemessage from '@/components/tchat/page_message'
    export default {
      components : {
          pagemessage
      },
        data: () => ({
          drawer:true,
            data_load:false,
            userId:null,
current_user_id: null,
select_comme:false,
commerce_id:null,
list_communication:[],
login:null,
messages:[],
current_id_com:null,
data_user:null,
is_compte_commerce:false,


        }),
        created:function() {
          this.login = this.currentUser().email;
            this.current_user_id = this.currentUser().uid

            this.$db.collection("users").doc(this.current_user_id).get().then((querySnapshot) => {
           this.data_user = querySnapshot.data() || {};
           this.is_compte_commerce = this.data_user.is_compte_commerce;
           if( this.is_compte_commerce) {
            this.getidcommerce();
          } else {
            this.getuserrooms();
          }
        })





        },
        methods: {
          getmessages(id) {

        console.log("chat/" + this.commerce_id + "/messages/" +id)
      let req =  firebase.database().ref("chat/" + this.commerce_id + "/messages/" +id).orderByKey().limitToLast(20)

      req.on("value", snapshot => {
this.messages = []
        snapshot.forEach((childSnapshot) => {
          let chat = childSnapshot.val()
          chat.key = childSnapshot.key
          this.messages.push(chat)
        })
        console.log(  this.messages)
      })



          },
          getuserrooms() {

            this.list_communication =[]
                  firebase.database().ref('userschat').child(this.current_user_id).child('commerces').orderByKey().limitToLast(20).once("value",(snapshot) => {

                    snapshot.forEach((childSnapshot) => {
                      let chat = childSnapshot.val()
                      chat.key = childSnapshot.key
                      this.list_communication.push(chat)
                    })
                    console.log(  this.list_communication)
                  })

          },
          sendMessage() {

const message = {
        message: this.message,
        userid:this.current_user_id,
        login: this.login,
        date: Date.now(),
      };

      this.message = "";
      firebase
        .database()
        .ref("chat/" + this.commerce_id + "/messages/" + this.current_id_com)
        .push(message);




  },

          getidcommerce() {


             this.$db.collection("commerce").doc(this.current_user_id).get().then((querySnapshot) => {
      this.commerce_id = querySnapshot.id;
  this.loadRecentChats(querySnapshot.id);
          });



          },
          loadRecentChats (id) {

            this.list_communication =[]
                  firebase.database().ref('chat').child(id).child('users').orderByKey().limitToLast(20).once("value",(snapshot) => {

                    snapshot.forEach((childSnapshot) => {
                      let chat = childSnapshot.val()
                      chat.key = childSnapshot.key
                      this.list_communication.push(chat)
                    })
                    console.log(  this.list_communication)
                  })
    },
          currentUser() {
            return firebase.auth().currentUser
          },





        }
    }
</script>
<style>

#chip-usage-example {
width:300px;
}

 .v-chip {
height:auto;
 }

 </style>
