<template>
  <div   >


  <v-card class="grey lighten-3"  :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >


  <!-- Stack the columns on mobile by making one full-width and the other half-width -->
  <v-row no-gutters>
  <v-col  cols="12"  md="12">
    <v-card>
    <v-card-text>
              <v-row>
                <v-col cols="8">

                  <v-text-field
                  dense
                  v-model="new_type"
                  label="Type"
                ></v-text-field>
                </v-col>
                <v-col cols=4>
                <v-btn
         class="mr-4"
         type="submit" @click="addtype()">Ajouter
        </v-btn>
             </v-col>





  </v-row>
  </v-card-text>
  </v-card>
  <template v-for="(p, i) in type_search" >
    <template v-if="p.load == true">

    <v-chip v-if="p.show == true" :key="'type'+ i" color="primary" class="ma-2"  close close-icon="mdi-close-circle" label @click:close="type_search[type_search.indexOf(p)].show = false, updatedata()" tile>

        {{p.name}} - {{p.nb}}
        </v-chip>
        <v-chip v-if="p.show == false" :key="'type'+ i" class="ma-2"  close close-icon="mdi-plus-circle" outlined label @click:close="type_search[type_search.indexOf(p)].show = true, updatedata()" tile>

            {{p.name}} - {{p.nb}}
            </v-chip>
          </template>
          <template v-else>
            <v-chip :key="'type'+ i" color="primary" class="ma-2"   label @click:close="type_search[type_search.indexOf(p)].show = false, updatedata()" tile>

                {{p.name}} - {{p.nb}}
                <v-progress-circular right
        indeterminate
        :size="20"
        color="white"
      ></v-progress-circular>
                </v-chip>

          </template>
  </template>

  </v-col>
</v-row>
  <v-row no-gutters>

    <v-col  cols="12"  md="6">
      <v-card>
      <mapi :data="places" :type="array_type" :reload="force_load" @changeposition="(v) => changeposition(v)"></mapi>
      </v-card>
    </v-col>




    <v-col  cols="12"  md="6">


<v-container>






      <v-row >
        <v-col v-for="(v, i) in places" :key="i" cols="12"  lg="3" md="6" sm="12" >
          <v-card  v-if="array_type[v.type_lafouine]" color="grey lighten-5">
            <v-theme-provider dark>
              <div>
                <v-img v-if="v.photos"
      height="120"
      :src="v.photos[0].getUrl()"
      :lazy-src="v.photos[0].getUrl()"
      ></v-img>


              </div>
            </v-theme-provider>


            <v-card-text>

          <b>{{v.name}}</b><br>
          {{v.formatted_address}}


            </v-card-text>
            <v-card-actions>
                  <v-btn
                    text link
                    @click="reveal = true" target="_blank" :href="'https://www.google.com/maps/place/' + v.name + '/'"
                  >
                    En savoir plus
                  </v-btn>
                </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-pagination
      v-model="page"
      :length="nb_page"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
    ></v-pagination>
      </v-container>
    </v-col>

<div :id="id"></div>




  </v-row>






  </v-card>

</div>

</template>

<script>
import mapi from '@/components/map'
//import firebase from "firebase";
import VueGeolocation from 'vue-browser-geolocation';
//import axios from "axios";
    export default {
      components: {
        mapi
      },
        data () {
            return {
              places:[],
              array_type:[],
              new_type:"",
              force_load:"",
              type_search:[{name:"Belvédère", load:false, show:true },
                {name:"cascade", load:false, show:true },
                {name:"eglise", load:false, show:true },
            //    {name:"source", load:false, show:true },
                {name:"lac", load:false, show:true },
                {name:"plage", load:false, show:true },
                {name:"chateau", load:false, show:true }],
              menu_payment:false,
              menu_livraison:false,
              dialog:false,
              distance: { label: 'Distance', val: 50, color: 'black' },

           min:0,
           page:1,
           nb_page:0,
           max:10000,
           items: [
  {id:0,name:'fruits_legumes', text:'Fruits et légumes' },
  {id:1,name:'viandes_poissons', text: 'Viandes et poissons ' },
  {id:2,name:'pains_patisseries', text: 'Pains et pâtisseries' },
  {id:3,name:'frais', text: 'Frais' },
  {id:4,name: 'epicerie_salee', text:'Épicerie salée ' },
  {id:5,name:'epicerie_sucree', text: 'Épicerie sucrée ' },
  {id:6,name:'bio', text: 'Bio et écolo ' },
  {id:7,name:'boissons', text: 'Boissons ' },
  ],
           items_mode :[
           {name:'vetements', text:  'Vêtements ' },
           {name:'chaussures', text:  'Chaussures ' },
           {name:'bijoux_montres', text:  'Bijoux et montres ' },
           {name:'maroquinerie_bagagerie', text:  'Maroquinerie et Bagagerie ' },
           {name:'sport', text:  'Sport ' },
           ],
           items_beaute:[
           {name: 'maquillage', text:'Maquillage ' },
           {name: 'soins_visage', text:'Soins visage' },
           {name: 'soins_corps', text:'Soins corps ' },
           {name: 'dentaire', text:'Hygiène dentaire ' },
           {name: 'capillaire', text:'Capillaire ' },
           {name:'bebe', text: 'Bébé ' },
         ],
         items_loisir:[
         {name:'livres', text: 'Livres ' },
         {name:'jeux', text: 'Jeux' },
         {name: 'high_tech', text:'High-Tech ' },
         {name:'sport', text:'Sport ' },

       ],
       items_maison: [
       {name:'decoration', text:  'Décoration ' },
       {name:'art_table', text:  'Art de la table' },
       {name:'meubles', text:  'Meubles' },
       {name:'entretien_nettoyage', text:  'Entretien et nettoyage ' },
       {name:'bricolage_jardin', text:  'Bricolage et Jardin ' },
  ],
  items_payment: [
  {name:'especes', text:  'Espèces' },
  {name:'carte', text:  'Carte bancaire' },
  {name:'cheques', text:  'Chèques' },
  ],
  items_expe: [
  {name:'commerce', text:  'Retrait en magasin' },
  {name:'colis', text:  'Livraison à domicile' },
  ],
categories:{},
global_categories:[],
payment:[],
livraison:[],

     selec_epic:[],
     selec_mode:[],
     selec_beaute:[],
     selec_loisir:[],
     selec_maison:[],
     coordinates:null,
     descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model_search: null,
      search: null,
myApi:null,
id:'id_div',
service:null

            }
        },
        mounted() {
this.service = new window.google.maps.places.PlacesService(document.getElementById(this.id));


    VueGeolocation.getLocation()
    .then(coordinates => {
      console.log(coordinates);
      this.coordinates = coordinates;

      this.findClosed(false)
    });
  },
  computed: {
        fields () {
          if (!this.model) return []

          return Object.keys(this.model).map(key => {
            return {
              key,
              value: this.model[key] || 'n/a',
            }
          })
        },

  commerce_search_comp(){
    return this.page ? this.commerce_search.slice((this.page-1)*9,this.page*9) : this.commerce_search
  }

      },


  methods:{
    updatedata() {

        this.type_search.forEach((item) => {


             this.array_type[item.name] = item.show

        });
        this.force_load = new Date().toString()

        //this.places = tab;

    },
    addtype() {

        this.type_search.push({name:this.new_type, load:false, show:true})
        this.new_type = "";
     this.findClosed(false)

    },
    getdata() {



    },
    findClosed(stat) {
console.log("find ")
      this.type_search.forEach((item) => {
        if(!item.load || stat) {
      //  const URL = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${item.name}&location=${this.coordinates.lat},${this.coordinates.lng}&radius=5&key=AIzaSyA1kPNgrtrkiEcs93q_OAvlcZ_0yqIl930`;

      var request = {
          query: item.name,
          location: { lat:this.coordinates.lat, lng: this.coordinates.lng },
          radius:5,

        };
        console.log(this.service)
      this.service.textSearch(request, (results, status)  => {
         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        console.log(results)
        let r = results
        let tab = []
         let nb = 0;
           for (var i = 0; i < r.length; i++) {

            //  console.log(this.getDistanceFromLatLng(this.coordinates.lat,this.coordinates.lng, r[i].geometry.location.lat(), r[i].geometry.location.lng(), "K"))

             if(this.getDistanceFromLatLng(this.coordinates.lat,this.coordinates.lng, r[i].geometry.location.lat(), r[i].geometry.location.lng(), "K") <= 10) {
                 r[i].type_lafouine = item.name;
                nb++;
                tab.push(r[i])
              }
          }
            item.nb = nb;
           this.array_type[item.name] = true;
           item.load = true;
this.places = tab.concat(this.places);
             console.log(this.places)
         }
       });
//  axios.get(URL, { crossdomain: true }).then(response => {
    //      let r = response.data.results
    //      let tab = []
    //    let nb = 0;
    //      for (var i = 0; i < r.length; i++) {
  //          console.log(this.getDistanceFromLatLng(this.coordinates.lat,this.coordinates.lng, r[i].geometry.location.lat, r[i].geometry.location.lng, "K"))

  //          if(this.getDistanceFromLatLng(this.coordinates.lat,this.coordinates.lng, r[i].geometry.location.lat, r[i].geometry.location.lng, "K") <= 10) {
    //          r[i].type_lafouine = item.name;
    //          nb++;
    //          tab.push(r[i])
    //        }
      //    }
  //        item.nb = nb;
  //        this.array_type[item.name] = true;
  //        item.load = true;
  //        this.places = tab.concat(this.places);
    //      console.log(this.places)
    //    }).catch(error => {
      //    console.log(error.message);
      //  });
      }
      });


},
    GetNameClass(array, val) {
      console.log(array)
      if(array.length != undefined) {
        for (var i = 0; i < array.length; i++) {

        if(array[i].name == val) {

          return array[i].text;

        }
        }
      }


    },
      changeDistance(v) {

            this.loaddata(this.coordinates, v )
            this.distance.val = v
      },
      changeposition(v) {
        console.log("vd")
this.places = []
  this.coordinates = v;
          this.findClosed(true)

      },
      getNamescategories() {
        let ar = []
        Object.getOwnPropertyNames(this.categories).forEach((val) => {

          if(this.categories[val] == true) {
            ar.push(val)
          }

  });
  this.global_categories = ar;
  return ar;

      },
      getautosearch(){
        if(this.model_search.length > 0) {

            this.commerce_search = this.model_search
              this.nb_page = Math.ceil(this.commerce_search.length/9)
        } else {
          this.changeSearch()
        }

      },
      getDistanceFromLatLng(lat1, lng1, lat2, lng2) { // miles optional

    function deg2rad(deg){return deg * (Math.PI/180);}
    function square(x){return Math.pow(x, 2);}
    var r=6371; // radius of the earth in km
    lat1=deg2rad(lat1);
    lat2=deg2rad(lat2);
    var lat_dif=lat2-lat1;
    var lng_dif=deg2rad(lng2-lng1);
    var a=square(Math.sin(lat_dif/2))+Math.cos(lat1)*Math.cos(lat2)*square(Math.sin(lng_dif/2));
    var d=2*r*Math.asin(Math.sqrt(a));

  return d;
  }



  }
    }
</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  .v-main__wrap {
    background-color:#eee;
  }
  .treeclass {

    background-color:#B0C5B9;
  }

  .v-treeview-node--leaf  {
        background-color:#E3EAE5;

  }

  .v-treeview-node--active {
    color:red;

  }
  .v-progress-circular {
    margin: 0.1rem;
  }


</style>
