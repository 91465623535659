// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import App from './App'
import { router } from './router'
import firebase from 'firebase/app';
import store from "./store";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";


import * as geofirestore from 'geofirestore';

Vue.config.productionTip = false


const configOptions = {
  apiKey: "AIzaSyCx42WIAeNMJLuwNE2UWHMF3wNK1Y6KoW4",
     authDomain: "la-fouine-locale.firebaseapp.com",
     projectId: "la-fouine-locale",
     storageBucket: "la-fouine-locale.appspot.com",
     messagingSenderId: "656211774964",
     appId: "1:656211774964:web:f56f1e23dcdac215b5fda1",
     measurementId: "G-B68LF3P4S3"
};

const firebaseApp = firebase.initializeApp(configOptions);

 firebase.analytics();
geofirestore.initializeApp(firebaseApp);
let db = firebaseApp.firestore();
db = geofirestore.initializeApp(db)

Vue.prototype.$db = db;

let app;
firebase.auth().onAuthStateChanged(user => {
  console.log("user", user)

  if(!app) {
      app = new Vue({
        router,
        vuetify,
        store,
        render: h => h(App),
      }).$mount('#app')
  }
});
