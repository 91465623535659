import Vue from 'vue'
import Router from 'vue-router'

import Shop from '@/components/Shop'

import Layout from '@/components/Layout'

import Account from '@/components/account.vue'
import CreateAccount from '@/components/createaccount.vue'
import Connect from '@/components/connect.vue'

import Map from '@/components/map_load.vue'
import Messagerie from '@/components/messagerie.vue'
import Mentions from '@/components/mentions.vue'
import forgotPassword from '@/components/forgotpassword.vue'
import firebase from "firebase";
Vue.use(Router)

export const router =  new Router({
  routes: [
    {
      path: '/',
      component: Layout,
      children:[
        {
          path:'/',
          component:Map,
          name:'Map'
        },
        {
          path:'/map',
          component:Map,
          name:'Map'
        },
        {
          path:'/account',
          component:Account,
          name:'Account'
        },
        {
          path:'/connect',
          component:Connect,
          name:'Connect'
        },
        {
          path:'/createaccount',
          component:CreateAccount,
          name:'CreateAccount'
        },
        {
          path:'/commerce/:commerce',
          component:Shop,
          name:'Category'
        },
        {
          path:'/messages',
          component:Messagerie,
          name:'Messagerie'
        },
        {
          path:'/mentions',
          component:Mentions,
          name:'Mentions'
        },
        {
          path:'/forgot-password',
          component:forgotPassword,
          name:'forgotPassword'
        },

      ]

    }
  ],
    mode:'history',
    scrollBehavior () {
  return { x: 0, y: 0 }
}
},

);


router.beforeEach((to, from, next) => {
  const privatePages = ['/account', '/test', ];
  const authRequired = privatePages.includes(to.path);


  const isAuthenticated = firebase.auth().currentUser;
    console.log("isauthenticated", isAuthenticated);

  if (authRequired && !isAuthenticated) {
    next('/connect');
  } else {

  next();

  }
});
