<template>
    <div>
      <v-row justify="center">
        <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
        <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="profile-img-card mb-4"
        />
        <v-form ref="form" v-model="valid_form" @submit.prevent="forgetPassword">


            <v-text-field v-model="user.email" type="email" :rules="emailRules"   label="Email"></v-text-field>


            <v-progress-linear v-show="loading" indeterminate color="cyan"></v-progress-linear>

            <v-btn block class="primary mt-2" type="submit" :disabled="loading || !valid_form">Réinitialiser mon mot de passe</v-btn>

              <v-alert  class="mt-4" v-if="message" type="primary">
                  {{message}}
              </v-alert>



        </v-form>

      </v-card-text>
    </v-card>
      </v-col>
    </v-row>
    </div>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      user: {
        email: '',

      },
        message: "",
        valid_form:false,
        loading:false,
        emailRules: [
        v => !!v || 'Veuillez ajouter un email',
        v => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(v) || 'email invalide.'
              },
            ],
    };
  },
  methods: {
    forgetPassword() {
        if(this.$refs.form.validate()) {
        firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
            this.message = "Nous avons envoyés un email de réinitialisation du mot de passe.";
            this.user = {
              email: ''
            }
        }).catch((error) => {
          alert(error)
        })
      }
    }
  }
};
</script>
<style scoped>


.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
