<template>
  <div>
  <v-row justify="center">
    <v-col cols="12" md="4" sm="6">
    <v-card>
      <v-card-text>
    <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
      class="profile-img-card mb-4"
    />
      <v-form  v-model="valid_form" ref="form" @submit.prevent="handleRegister">
        <template v-if="!successful">



          <v-text-field
              v-model="user.email"
              type="email"
              label="Email"
                :rules="emailRules"
              name="email">

          </v-text-field>


          <v-text-field v-model="user.password"
              type="password"
              label="Mot de passe"
                :rules="passwordRules"
              name="password">
            </v-text-field>


            <v-checkbox v-model="user.is_compte_commerce" label="Je souhaite créer un compte pour mon commerce"></v-checkbox>
            <v-text-field v-if="user.is_compte_commerce" v-model="user.name_commerce"
                type="text"
                label="Nom de votre commerce"
                :rules="nameRules">
              </v-text-field>
              <p> En cliquant sur le bouton S'inscrire, vous indiquez avoir pris connaissance et accepté les <a target="_blank" href="/mentions">Conditions Générales d'Utilisation</a>

              </p>
            <v-btn block type="submit" class="primary" :disabled="!valid_form">S'inscrire</v-btn>

          </template>
      </v-form>

      <v-alert  class="mt-4" v-if="message" :color="successful ? 'primary' : 'error'">
          {{message}}
      </v-alert>

    </v-card-text>
  </v-card>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import firebase from "firebase";


export default {
  name: 'Register',
  data() {
    return {
      user:{is_compte_commerce:false, name_commerce:""},
      submitted: false,
      successful: false,
      message: '',
      valid_form:false,
      error: null,
      usernameRules: [
     v => !!v || 'Veuillez ajouter un numéro de serie',
     v => (v && v.length >= 4) || 'Le nom d\'utilisateur doit comporter plus de 4 caractères',
   ],
   passwordRules: [
  v => !!v || 'Veuillez ajouter un mot de passe',
  v => (v && v.length >= 6) || 'Le mot de passe doit comporter plus de 6 caractères',
],
nameRules: [
v => !!v || 'Veuillez ajouter un nom de commerce',
],
emailRules: [
v => !!v || 'Veuillez ajouter un email',
v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'email invalide.'
      },
    ],

    };
  },
  computed: {
    loggedIn() {
      return false;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {

    handleRegister() {
      if(this.$refs.form.validate() ) {
    firebase
        .auth()
        .createUserWithEmailAndPassword(this.user.email, this.user.password)
        .then(data => {



         if(this.user.is_compte_commerce) {
           let to_update = {
             is_compte_commerce:this.user.is_compte_commerce,
             date: Date.now(),
             name_commerce:this.user.name_commerce,
              email:this.user.email
           }
           console.log()

           this.$db.collection("users").doc(data.user.uid).set(to_update, { merge: true }).then(() => {

           this.is_loading =false;
        });
         this.$db.collection("commerce").doc(data.user.uid).set(to_update, { merge: true }).then(() => {
      console.log("Commerce enregistré");
         this.is_loading =false;
      });
    } else {
      let to_update = {
        is_compte_commerce:false,
        date: Date.now(),
        email:this.user.email
      }
      this.$db.collection("users").doc(data.user.uid).set(to_update, { merge: true }).then(() => {
   console.log("Document successfully written!");
      this.is_loading =false;
   });
    }
            this.successful = true;
            this.message = "Utilisateur ajouté."
        })
        .catch(err => {
          console.log(err)

          this.successful = false;
          switch (err.code) {
            case "auth/email-already-in-use":
                this.message = "Email déjà utilisé."
              break;
              case "auth/user-not-found":
                  this.message = "Email non enregistré."
                break;
            default:
            this.message = err.message;
          }

        //  this.error = err.message;
        });
      }
  }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
