<template>

  <div>
    <v-row>

      <v-list-item v-for="(n, i) in tab_file"   :key="'img'+ i" >
  <v-list-item-content>
    <v-list-item-title>
        <v-text-field label="Nom du fichier" class="purple-input" v-model="n.name" placeholder="Nom du fichier" />
    </v-list-item-title>
  </v-list-item-content>
  <v-list-item-icon>
    <v-btn icon target="_blank" :href="n.url_file">
      <v-icon>mdi-file</v-icon>
    </v-btn>
    <v-btn icon @click="tab_file.splice(i, 1)">
      <v-icon>mdi-delete</v-icon>
    </v-btn>

           </v-list-item-icon>
</v-list-item>

  </v-row>
      <v-row>
        <v-col cols="8">
    <v-file-input multiple accept="image/jpeg, .pdf, .docs" type="file"  v-model="files" @change="detectFiles(files)" show-size label="Ajouter une document"> ></v-file-input>
  </v-col>
  <v-col cols="4">
    <v-progress-circular
    v-if="is_uploading"
     :rotate="90"
     :size="100"
     :width="15"
     :value="bufferload"
     color="red"
   >
     {{ bufferload }}
   </v-progress-circular>
 </v-col>
  </v-row>
  </div>

</template>

<script>
import firebase from 'firebase'
export default {
  props: ['value'],
  data () {
    return {
      progressUpload: 0,
      file: File,
      files:undefined,
      uploadTask: '',
      userId:null,
      is_uploading:false,
      bufferload:0,
      tab_file:[],
    }
  },
  watch: {
    value: function (val) {
    this.tab_file = val;
    },
    uploadTask: function() {
      this.uploadTask.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.$emit('url', downloadURL)
        })
      })
    }
  },
  created:function() {
    if(this.value) {
      this.tab_file = this.value
    }
      this.userId = firebase.auth().currentUser.uid;

  },
  methods: {
    detectFiles (fileList) {
      Array.from(Array(fileList.length).keys()).map( x => {
        this.upload(fileList[x])
      })
    },
    upload (file) {


    if (!file) {
console.log("Please select a file!");
  return;
}
this.is_uploading = true;
const storageRef=firebase.storage().ref(this.CreateObjectId() + '.' + file.name.split('.').pop()).put(file);
storageRef.on(`state_changed`,snapshot=>{ this.bufferload = Math.round((snapshot.bytesTransferred/snapshot.totalBytes)*100);
  }, error=>{console.log(error.message)},
()=>{this.bufferload=100;
  this.is_uploading = false;

    storageRef.snapshot.ref.getDownloadURL().then((url)=>{
        this.tab_file.push({url_file:url, path:storageRef.snapshot.ref.location.path})
        this.$emit('input', this.tab_file)

      });
    }
  );



    },
    CreateObjectId() {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16);

    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
}

  },

}
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
</style>
