<template>
  <div>
      <v-card class="grey lighten-3"  :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >
    <template v-if="!loggedIn">
    <v-row justify="center">
      <v-col cols="12" md="4" sm="6">
      <v-card>
        <v-card-text>
      <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card mb-4"
      />
      <v-form ref="form" v-model="valid_form" @submit.prevent="handleLogin">


          <v-text-field v-model="user.email" type="email" :rules="emailRules"   label="Email"></v-text-field>

          <v-text-field v-model="user.password"  :rules="passwordRules" type="password"  label="Mot de passe"></v-text-field>

          <v-progress-linear v-show="loading" indeterminate color="cyan"></v-progress-linear>

          <v-btn block class="primary mt-2" type="submit" :disabled="loading || !valid_form">Se connecter</v-btn>

            <v-alert  class="mt-4" v-if="message" type="error">
                {{message}}
            </v-alert>




      </v-form>
      <v-btn block class="primary mt-2" to="/createaccount">Créer un compte</v-btn>
      <br>
   <router-link to="forgot-password">Mot de passe oublié ?</router-link>
    </v-card-text>
  </v-card>
    </v-col>
  </v-row>
</template>
</v-card>
  </div>
</template>

<script>

import firebase from "firebase";
export default {
  name: 'Login',
  data() {
    return {

      loading: false,
      message: '',
      user:{},
      valid_form:false,
      emailRules: [
         v => !!v || 'Email requis',
         v => /.+@.+\..+/.test(v) || 'Email non valide',
       ],

   passwordRules: [
  v => !!v || 'Veuillez ajouter un mot de passe',

],
    };
  },
  computed: {
    loggedIn() {
      return firebase.auth().currentUser;
    }
  },
  created() {
  if(this.loggedIn) {
      this.$router.push('/account');
  }

  },
  methods: {

    handleLogin() {
          if(this.$refs.form.validate()) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(data => {
          console.log(data)
          this.$router.push('/account');
        })
        .catch(err => {
          console.log(err)

          switch (err.code) {
            case "auth/wrong-password":
                this.message = "Mot de passe invalide."
              break;
              case "auth/user-not-found":
                  this.message = "Email non enregistré."
                break;
            default:
              this.message = err.message;
          }


        });
      }
    }
  }
};
</script>

<style scoped>


.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
