<template>
<div class="map_carte">
<section>

<v-card  :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >

<l-map  style="height: 100%; z-index: 1;" :zoom="zoom" :center="center" :options="{zoomControl: false}">
<l-tile-layer url="https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=c80688e51ba844f6b00ecd4720ba318a"></l-tile-layer>
<l-control :position="'topleft'" >
  <v-card  width="300">
    <v-card-text>
  <vue-google-autocomplete
      id="map"
      width="500"
      placeholder="Adresse"
      v-on:placechanged="getAddressData"

  >
  </vue-google-autocomplete>
</v-card-text>
</v-card>
  </l-control>

<l-marker :lat-lng="[p.geometry.location.lat(), p.geometry.location.lng()]"
        v-for="(p, i) in tab_position_show" :key="i" >
        <template>
       <l-icon :icon-anchor="[20, 40]">
         <v-icon size="40" color="#000">
   mdi-map-marker
 </v-icon>

               </l-icon>
      <l-popup>
        <v-card
        class="mx-auto"
        width="250"
        >
          <v-theme-provider dark>
            <div>
              <v-img v-if="p.photos"
height="120"
:src="p.photos[0].getUrl()"
:lazy-src="p.photos[0].getUrl()"
></v-img>
<v-card-text>
  {{p.name}}
</v-card-text>
            </div>
          </v-theme-provider>




        </v-card>

    </l-popup>
  </template>
    </l-marker>
    <l-circle
          :lat-lng="marker_pos"
          :radius="radius"
          :fill="false"
          color="red"
        />
	<l-marker v-if="show" :lat-lng="marker_pos">
    <l-icon :icon-anchor="[20, 20]">
            <v-avatar  size="40" dense color="white">
              <v-icon>
        mdi-home
      </v-icon>

   </v-avatar>

            </l-icon>


	</l-marker>

</l-map>
</v-card>


</section>
</div>
</template>

<script>
import { LMap, LTileLayer,  LMarker,  LIcon, LPopup, LControl, LCircle } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {icon } from "leaflet";
import VueGeolocation from 'vue-browser-geolocation';
import VueGoogleAutocomplete from  '@/plugins/VueGoogleAutocomplete'
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;

export default {
  components: {  LMap,
    LTileLayer,
    LMarker,LPopup,  LControl, VueGoogleAutocomplete, LIcon,LCircle
	},
  props:['data', 'type', 'reload'],
  data () {
    return {
url: 'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/VIIRS_CityLights_2012/default/',
radius:10000,
zoom:   12,
center: [46.662475, 5.5729159],
marker_pos: [0,0],
show:false,
geojson:[],
iconSize: 64,
tab_position:[],
is_loading:false,
tab_position_show:[],
  distance: { label: 'Distance', val: 50, color: 'black' },
icon: icon({
        iconUrl: "/img/icon/location-sign.png",
        iconSize: [32, 32],
        iconAnchor: [32, 32]
      }),
      icon_2: icon({
              iconUrl: "/img/icon/house.png",
              iconSize: [32, 32],
              iconAnchor: [32, 32]
            }),
   };
  },
  mounted() {
    console.log(this.$vuetify.application.top)
	document.title = 'Fouine local';
  VueGeolocation.getLocation()
  .then(coordinates => {
    console.log(coordinates);
	this.show = true;
	this.marker_pos = [coordinates.lat, coordinates.lng];
  this.center = [coordinates.lat, coordinates.lng];
  });
this.tab_position_show = this.getjson(this.data)




},
watch: {
  data: function(newVal) { // watch it
        this.tab_position_show =  this.getjson(newVal)

      },

      reload: {
        handler :function() { // watch it
        console.log("fe")
            this.tab_position_show =  this.getjson(this.data)
            console.log(this.tab_position_show)
          },
          deep:true
        }

      },


  methods: {
    changeDistance() {
      this.$emit('changeDistance', this.distance.val)

    },
    getAddressData: function (addressData) {


      this.marker_pos = [addressData.latitude, addressData.longitude]
      this.center = [addressData.latitude, addressData.longitude]
this.zoom = 10;
  this.$emit('changeposition', {lat:addressData.latitude, lng:addressData.longitude})
    },
    getjson(data) {
let tab = []
data.forEach((item) => {
if(this.type[item.type_lafouine]) {
  tab.push(item)
}
});

return tab;
    }
  }
}
</script>
<style>

.leaflet-popup-tip-container {
  display:none;
}

.leaflet-popup-content-wrapper {
  border-radius: 0px;
  padding: 0;
   width:100%;

   background-color:rgb(255,255,255,0);
}
.leaflet-popup-content {
  padding: 0;
  margin:0;

}
.leaflet-popup  {
  padding: 0;
}

.custom-control {

  padding:6px;
}
.full_card {
  width:100%;
}
</style>
