<template>
  <div>

        <v-container v-if="is_load">
<v-stepper
    v-model="e6"

  >

  <v-stepper-header>
    <v-stepper-step :complete="e6 > 1"  editable step="1">
      Coordonnées
    </v-stepper-step>
 <v-divider></v-divider>
    <v-stepper-step :complete="e6 > 2"  editable step="2">
      Catégories
    </v-stepper-step>
 <v-divider></v-divider>
<v-stepper-step :complete="e6 > 3"  editable step="3">Mes Produits</v-stepper-step>
             <v-divider></v-divider>
    <v-stepper-step :complete="e6 > 4"  editable step="4">
      Moyens de paiement
    </v-stepper-step>
    <v-divider></v-divider>
    <v-stepper-step :complete="e6 > 5"  editable step="5">
   Options de livraison
    </v-stepper-step>
</v-stepper-header>
<v-stepper-items>
    <v-stepper-content step="1">
      <v-card  elevation="0" class="mb-12">
          <v-container>
            <v-row>
                <v-col cols="12" md="8" >
              <v-row>
            <v-col cols="12" md="4" >
              <v-text-field label="Nom du commerce" class="purple-input" v-model="data_user.name_commerce"/>
            </v-col>
          </v-row>
          <v-row>
        <v-col cols="12" md="12" >
          <v-textarea
          v-model="data_user.description"
           label="Description"
           value=""

         ></v-textarea>
        </v-col>
      </v-row>
                  <v-row>
                        <v-col cols="12" md="4" >
                          <v-text-field label="Adresse Email" class="purple-input" v-model="data_user.email" />
                        </v-col>
                        <v-col cols="12" md="4" >
                          <v-text-field label="Numéro de téléphone" class="purple-input" v-model="data_user.numero_phone" />
                        </v-col>
                        <v-col cols="12" md="4" >
                          <v-text-field label="Site internet" class="purple-input" v-model="data_user.website" />
                        </v-col>

                        <v-col cols="12" md="12" >
                          <vue-google-autocomplete
                              id="map"
                              :text="data_user.address"
                              width="300"
                              placeholder="Adresse"
                              v-on:placechanged="getAddressData"

                          >
                          </vue-google-autocomplete>
                        </v-col>
                        <v-col cols="12" md="12" >
                            <mapaccount :data="withTooltip"></mapaccount>


                        </v-col>
                          <v-col cols="12" md="12" >
                            Horaires d’ouverture
                            <template v-if="data_user.horaires">
                          <addday v-model="data_user.horaires.lundi" day="Lundi"></addday>
                            <addday v-model="data_user.horaires.mardi" day="Mardi"></addday>
                              <addday v-model="data_user.horaires.mercredi" day="Mercredi"></addday>
                                <addday v-model="data_user.horaires.jeudi" day="Jeudi"></addday>
                                  <addday v-model="data_user.horaires.vendredi" day="Vendredi"></addday>
                                    <addday v-model="data_user.horaires.samedi" day="Samedi"></addday>
                                      <addday v-model="data_user.horaires.dimanche" day="Dimanche"></addday>
                                    </template>



                            </v-col>

              </v-row>
                </v-col>
                  <v-col cols="12" md="4" >
                        <uploadpicture v-model="data_user.tab_img"></uploadpicture>
                  </v-col>
                </v-row>

              </v-container>
    </v-card>
      <v-btn color="primary" @click="e6 = 2">
        Suivant
      </v-btn>

    </v-stepper-content>


    <v-stepper-content step="2">
      <v-card  elevation="0" class="mb-12">
          <v-container  class="py-0">
              <v-row>
                <v-col cols="12" md="4" >
                  <v-card-title>EPICERIE</v-card-title>
                  <template v-for="(item, i) in items_epic">

                      <v-checkbox dense :key="'epic'+ i" v-model="categories[item.name]" :label="item.text"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="4" >
                  <v-card-title>MODE</v-card-title>
                  <template v-for="(item, i) in items_mode">

                      <v-checkbox dense :key="'mode'+ i" v-model="categories[item.name]" :label="item.text"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="4" >
                  <v-card-title>BEAUTE ET HYGIENE</v-card-title>
                  <template v-for="(item, i) in items_beaute">

                      <v-checkbox dense :key="'mode'+ i" v-model="categories[item.name]" :label="item.text"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="4" >
                  <v-card-title>LOISIRS</v-card-title>
                  <template v-for="(item, i) in items_loisir">

                      <v-checkbox dense :key="'loisir'+ i" v-model="categories[item.name]" :label="item.text"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="4" >
                  <v-card-title>MAISON</v-card-title>
                  <template v-for="(item, i) in items_maison">

                      <v-checkbox dense :key="'maison'+ i" v-model="categories[item.name]" :label="item.text"></v-checkbox>
                  </template>
                </v-col>
              </v-row>
              </v-container>
    </v-card>
      <v-btn color="primary" @click="e6 = 3">
        Suivant
      </v-btn>
      <v-btn  @click="e6 = 1" text>
       Retour
     </v-btn>

    </v-stepper-content>



        <v-stepper-content step="3">
          <v-card  elevation="0" class="mb-12">
              <v-container  class="py-0">
                  <v-row>
                    <v-col cols="12" md="12" >
Afin de faciliter davantage les échanges avec le client, vous pouvez télécharger ici une liste de produits disponibles dans votre commerce.
<br>
                          <uploadfile v-model="data_user.tab_files"></uploadfile>
                    </v-col>
                  </v-row>
                  </v-container>
        </v-card>
          <v-btn color="primary" @click="e6 = 4">
            Suivant
          </v-btn>
          <v-btn  @click="e6 = 2" text>
           Retour
         </v-btn>

        </v-stepper-content>

    <v-stepper-content step="4">
      <v-card elevation="0" class="mb-12">
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="6" >
                <v-card-title>Moyens de payment</v-card-title>
                <template v-for="(item, i) in items_payment">

                    <v-checkbox dense :key="'payment'+ i" v-model="payment[item.name]" :label="item.text"></v-checkbox>
                </template>
              </v-col>

          </v-row>
              </v-container>
    </v-card>
      <v-btn color="primary" @click="e6 = 5">
        Suivant
      </v-btn>
      <v-btn  @click="e6 = 3" text>
       Retour
     </v-btn>

   </v-stepper-content>
    <v-stepper-content step="5">
         <v-card elevation="0" class="mb-12">
             <v-container class="py-0">
               <v-row>

                 <v-col cols="12" md="6" >
                   <v-card-title>Options de livraison</v-card-title>
                   <template v-for="(item, i) in items_expe">

                       <v-checkbox dense :key="'reception'+ i" v-model="reception[item.name]" :label="item.text"></v-checkbox>
                   </template>
                 </v-col>
             </v-row>
                 </v-container>
       </v-card>
         <v-btn color="primary" @click="updateProfile">
           Enregistrer
         </v-btn>
         <v-btn @click="e6 = 4" text>
          Retour
        </v-btn>

       </v-stepper-content>
    <v-btn
      fab

      x-large
      bottom
       right
       fixed
       color="primary"
       @click="updateProfile"
    > <v-icon>mdi-content-save</v-icon></v-btn>



</v-stepper-items>
  </v-stepper>
      </v-container>

</div>
</template>


<script>
import firebase from "firebase";

import { latLng } from "leaflet";




import uploadpicture from '@/components/account/upload_picture'
import uploadfile from '@/components/account/upload_file'
import mapaccount from '@/components/account/map_account'
import VueGoogleAutocomplete from  '@/plugins/VueGoogleAutocomplete'
import addday from '@/components/addday'



    export default {
      components: {
        VueGoogleAutocomplete,

uploadpicture,
uploadfile,
mapaccount,

  addday
      },
    data () {
      return {
        zoom: 13,
center: [46.662475, 5.5729159],
url: 'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/VIIRS_CityLights_2012/default/',
attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
withTooltip: latLng(47.41422, -1.250482),
currentZoom: 10,
currentCenter: latLng(47.41322, -1.219482),
showParagraph: false,


mapOptions: {
zoomSnap: 0.5
},
categories:{},
payment:{},
reception:{},
showMap: true,

           e6: 1,
           items_epic: [
  {id:0,name:'fruits_legumes', text:'Fruits et légumes' },
  {id:1,name:'viandes_poissons', text: 'Viandes et poissons ' },
  {id:2,name:'pains_patisseries', text: 'Pains et pâtisseries' },
  {id:3,name:'frais', text: 'Frais' },
  {id:4,name: 'epicerie_salee', text:'Épicerie salée ' },
  {id:5,name:'epicerie_sucree', text: 'Épicerie sucrée ' },
  {id:6,name:'bio', text: 'Bio et écolo ' },
  {id:7,name:'boissons', text: 'Boissons ' },
  ],
           items_mode :[
           {name:'vetements', text:  'Vêtements ' },
           {name:'chaussures', text:  'Chaussures ' },
           {name:'bijoux_montres', text:  'Bijoux et montres ' },
           {name:'maroquinerie_bagagerie', text:  'Maroquinerie et Bagagerie ' },
           {name:'sport', text:  'Sport ' },
           ],
           items_beaute:[
           {name: 'maquillage', text:'Maquillage ' },
           {name: 'soins_visage', text:'Soins visage' },
           {name: 'soins_corps', text:'Soins corps ' },
           {name: 'dentaire', text:'Hygiène dentaire ' },
           {name: 'capillaire', text:'Capillaire ' },
           {name:'bebe', text: 'Bébé ' },
         ],
         items_loisir:[
         {name:'livres', text: 'Livres ' },
         {name:'jeux', text: 'Jeux' },
         {name: 'high_tech', text:'High-Tech ' },
         {name:'sport', text:'Sport ' },

       ],
       items_maison: [
       {name:'decoration', text:  'Décoration ' },
       {name:'art_table', text:  'Art de la table' },
       {name:'meubles', text:  'Meubles' },
       {name:'entretien_nettoyage', text:  'Entretien et nettoyage ' },
       {name:'bricolage_jardin', text:  'Bricolage et Jardin ' },
  ],
  items_payment: [
  {name:'especes', text:  'Espèces' },
  {name:'carte', text:  'Carte bancaire' },
  {name:'cheques', text:  'Chèques' },
  ],
  items_expe: [
  {name:'commerce', text:  'Retrait en magasin' },
  {name:'colis', text:  'Livraison à domicile' },
  ],

        is_loading: false,
        is_load:false,
        userId:null,
        data_user: {},
        successful: false,
        message: '',
        error:null,
        secondaryApp:null,
        dialog_createuser:false,
        type_user:'operateur',
        email_user:null,
        user_user:null,
        password_user:null,
        valid_form:false,
        is_compte_commerce:false,
      items:[],
      load:false,

      }
    },

    mounted () {





           this.userId = firebase.auth().currentUser.uid
           this.is_loading =true;
              this.$db.collection("users").doc(this.userId).get().then((querySnapshot) => {
             console.log(querySnapshot.data())
             this.data_user = querySnapshot.data() || {};

             this.is_compte_commerce = this.data_user.is_compte_commerce;
             if( this.is_compte_commerce) {
               this.$db.collection("commerce").doc(this.userId).get().then((querySnapshot) => {
              console.log(querySnapshot.data())
              this.data_user = querySnapshot.data() || {};
              if(this.data_user.longitude != undefined && this.data_user.latitude) {
                this.center = [ this.data_user.latitude, this.data_user.longitude]
                this.withTooltip =  [ this.data_user.latitude, this.data_user.longitude]
              }
              this.data_user.horaires = this.data_user.horaires || {}

              this.categories = this.data_user.categories || {},
              this.payment = this.data_user.payment ||  {},
              this.reception = this.data_user.reception || {},

               this.is_loading =false;
            });


             }
             this.load = true;
             this.is_load = true;
              this.is_loading =false;
           });


    },

    computed: {
   currentUser() {
     return firebase.auth().currentUser
   },
 },
 methods: {
   updateProfile() {

     let class_name = Object.getOwnPropertyNames(this.categories)


        let to_update = {
          name_commerce:this.data_user.name_commerce || "",
          description:this.data_user.description ||"",
          email:this.data_user.email || "",
      numero_phone:this.data_user.numero_phone || "",
       website:this.data_user.website || "",
       tab_img:this.data_user.tab_img || "",
        tab_files:this.data_user.tab_files || "",
       address:this.data_user.address || "",
       "class": class_name,
       adress_data:this.data_user.adress_data || "",
       coordinates: new firebase.firestore.GeoPoint(this.data_user.adress_data.latitude, this.data_user.adress_data.longitude),
       longitude:this.data_user.adress_data.longitude || "",
       latitude:this.data_user.adress_data.latitude || "",
       categories:this.categories || {},
       payment:this.payment ||  {},
       reception:this.reception || {},
       horaires:this.data_user.horaires || {}

     }

     console.log(to_update)
     this.is_loading =true;
     this.$db.collection("commerce").doc(this.userId).set(to_update, { merge: true }).then(() => {
  console.log("Document successfully written!");
     this.is_loading =false;
  });


   },

   getAddressData: function (addressData, placeResultData, id) {
     this.data_user.address = placeResultData.formatted_address
     this.data_user.adress_data = addressData

     this.center = [addressData.latitude, addressData.longitude]
     this.withTooltip = [addressData.latitude, addressData.longitude]

     console.log(id)
   }

 }
  }
</script>
